import { Component, OnDestroy, OnInit } from '@angular/core';

import { ToastService } from '../services/toast.service';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    standalone: false
})
export class ToastComponent implements OnInit, OnDestroy {
  message: string;
  hideToast = true;

  private destroyed = new Subject<void>();

  constructor(private toastService: ToastService) {}

  ngOnInit() {
    const ob = this.toastService.toastState.pipe(takeUntil(this.destroyed));
    ob.pipe(debounceTime(6000)).subscribe(() => {
      this.hideToast = true;
    });
    ob.pipe(debounceTime(7000)).subscribe(() => {
      this.message = '';
    });
    ob.subscribe((message: string) => {
      this.message = message;
      this.hideToast = false;
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
  }
}
