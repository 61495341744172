import { User } from './../../services/user';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { CurUserService } from '../../services/cur-user.service';

import { XctFormValidationService } from '../../services/form_validation.service';
import { XctTypesUtilityShim } from '../../types/xctTypesUtilityShim';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-form-elements-user-name-and-email',
    templateUrl: 'form-elements-user_name_and_email.component.html',
    styleUrls: ['../form-elements.scss', 'form-elements-user_name_and_email.component.scss'],
    providers: [XctFormValidationService],
    standalone: false
})
export class FormElementsUserNameAndEmailComponent implements OnInit {
  @Input() user: User;
  editUserForm: FormGroup;
  error: Record<string, boolean> = {};
  formSuccessText = '';
  loading = false;
  public alerts: {type: string; msg: string; timeout: number}[] = [];
  private userEmailChanged: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private curUserService: CurUserService
  ) {}

  ngOnInit() {
    const isDataPresent = this.user ? true : false;

    this.editUserForm = this.formBuilder.group({
      first_name: [isDataPresent ? this.user.first_name : '', [Validators.required, Validators.minLength(3)]],
      last_name: [isDataPresent ? this.user.last_name : '', [Validators.required, Validators.minLength(3)]],
      email1: [isDataPresent ? this.user.email1 : '', [Validators.required, XctFormValidationService.emailAddress]],
    });

    if (!this.user.email1_verified) {
      this.editUserForm.get('email1').disable();
    }

    this.editUserForm.get('email1').valueChanges
      .pipe(
        debounceTime(500)
      )
      .subscribe(res => {
        this.userEmailChanged = res !== this.user.email1;
      });
  }

  saveUser() {
    if (this.editUserForm.invalid) {
      return;
    }
    // Clear any extant messages.
    this.clearFormMessages();

    const xctTypesUtilityShim = new XctTypesUtilityShim();
    const fieldsToCheck = ['first_name', 'last_name', 'email1'];
    const hashForAPI = xctTypesUtilityShim.pluckOnlyChangedFieldsFromForm(this.user, this.editUserForm, fieldsToCheck);

    // This gets back a Promise.
    const updatedUser = this.curUserService.updateUserProfile(hashForAPI);
    this.loading = true;

    // API returns error messages that need to show up on form,
    // e.g., "Can not change email because old email was not verified. Try using forgot password with your old email".
    updatedUser
      .then(() => {
        const emailAddressThatChanged = (hashForAPI as any).email1;
        this.loading = false;

        this.setAlertMessage(
          'success',
          emailAddressThatChanged ?
            (`Your settings has been successfully updated. Please check your ` +
            `mail at ${emailAddressThatChanged} to confirm this email address.`) :
            'Your settings has been successfully updated.'
        );
      })
      .catch((resp: any) => {
        console.error(resp);
        this.error = {};
        this.loading = false;
        this.setAlertMessage(
          'danger',
          'There was an error changing your account information'
        );
      });
  }

  setAlertMessage(type: string, message: string, timeout?: number) {
    this.alerts = [];
    this.alerts.push({
        type,
        msg: message,
        timeout: timeout ? timeout : 5000
    });
  }

  clearFormMessages(): void {
    this.error = {};
    this.formSuccessText = '';
  }

  public checkEmailStatus(): void {
    if (!this.user.email1_verified) {
      this.setAlertMessage(
        'danger',
        'Please verify your email by clicking on the email sent to you, or use the <a href="/forgot_password">forgot password</a> feature to resend the email verification.',
        10000
      );
    }
  }
}
