<app-notification-bar></app-notification-bar>

<nav
  class="navbar navbar-dark xsact-nav"
  #userDropdownMenu
  [ngClass]="{'floating': floating}">
  <a class="navbar-brand" href="#">
    <img src="/assets/images/transact_logo.png" />
  </a>
  <ng-container *ngIf="!isLoggedIn">
    <div class="navbar-nav mr-auto"></div>
    <ul class="navbar-nav mr-5 page-links" [ngSwitch]="currentRoute" >
      <ng-container *ngSwitchCase="'/'">
        <li>
          <a class="nav-link" routerLink="/landing/publisher" i18n>Publishers</a>
        </li>
        <li>
          <a class="nav-link" routerLink="/landing/consumer" i18n>Consumers</a>
        </li>
      </ng-container>
      <ng-container *ngSwitchCase="'/signup'">
        <li>
          <a class="nav-link" routerLink="/signup" [queryParams]="{ which: 'publisher' }" i18n>Publishers</a>
        </li>
        <li>
          <a class="nav-link" routerLink="/signup" [queryParams]="{ which: 'reader' }" i18n>Consumers</a>
        </li>
      </ng-container>
      <li *ngSwitchDefault>
        <a class="nav-link" routerLink="/" i18n>Home</a>
      </li>
    </ul>
    <ul class="navbar-nav mr-5 page-links" [ngSwitch]="currentRoute">
      <li>
        <a class="nav-link" *ngSwitchCase="'/landing/publisher'" routerLink="/signup" [ngClass]="{ 'current-link' : ('/signup' === currentRoute)}"
          [queryParams]="{ returnUrl: returnUrl || '' , which: 'publisher' }">
            <span i18n>Signup</span><span class="separator"></span>
        </a>
        <a class="nav-link" *ngSwitchCase="'/landing/consumer'" routerLink="/signup" [ngClass]="{ 'current-link' : ('/signup' === currentRoute)}"
          [queryParams]="{ returnUrl: returnUrl || '' , which: 'reader' }">
            <span i18n>Signup</span><span class="separator"></span>
        </a>
        <a class="nav-link" *ngSwitchDefault routerLink="/signup" [ngClass]="{ 'current-link' : ('/signup' === currentRoute)}"
          [queryParams]="{ returnUrl: returnUrl || '' , which: '' }">
            <span i18n>Signup</span><span class="separator"></span>
        </a>
      </li>
      <li>
        <a class="nav-link" routerLink="/login"
           [queryParams]="returnUrl ? { returnUrl: returnUrl } : ''"
           [ngClass]="{ 'current-link' : ('/login' === currentRoute)}" i18n>Login</a>
      </li>
    </ul>
  </ng-container>
  <div class="user-lang-navigation">
    <button
    (click)="toggleCollapsedNavbar()"
    class="user-menu-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
    *ngIf="isLoggedIn">
      <span id="userMenu" class="username-label" *ngIf="user.first_name || user.last_name; else noNameText">
        {{ user.first_name + ' ' + user.last_name }}
      </span>
      <ng-template #noNameText><span id="userMenu">{{ user.email1 }}</span></ng-template>
      <img class="profile-image"
        [src]="user.profile_image +'?d=' + updatedTimestamp"
        appFallbackImage="/assets/images/plainAvatar.png" />

        <ul class="navbar-nav user-menu" *ngIf="isLoggedIn">
          <li class="nav-item dropdown">
            <div
              class="dropdown-menu"
              aria-labelledby="navbarDropdown"
              [ngClass]="{ 'active': headerCollapsedMenuOpen }">
              <div class="publishers-selector" *ngIf="groupPublishers && groupPublishers.length > 1">
                <label>Select Publisher Profile</label>
                <ul>
                  <li *ngFor="let publisher of groupPublishers"
                    [ngClass]="{'selected': publisher.selected}"
                    [routerLink]="['/publisher', publisher.id, 'summary']">
                    <div class="tt__image-wrapper image-40-40 circle">
                      <img [src]="publisher.profile_image"
                        appFallbackImage="/assets/images/plainAvatar.png" />
                    </div>
                    <div class="name-status">
                      <span class="title">{{ publisher.org }}</span>
                      <span class="status tt__text--blue" *ngIf="publisher.own">(owner)</span>
                    </div>
                  </li>
                </ul>
              </div>
              <!-- note: use href so bizops users can switch from other app-->
              <a *ngIf="user.buyer && !user.seller" href="/user/dashboard" class="dropdown-item" i18n>Dashboard</a>
              <a *ngIf="user.seller && (groupPublishers && groupPublishers.length === 1)"
                routerLink="/publisher/summary"
                class="dropdown-item" i18n>Publisher Summary</a>
              <a *ngIf="user.seller" routerLink="/user/dashboard" class="dropdown-item" i18n>User Dashboard</a>
              <a *ngIf="user.staff" href="/bizops/" class="dropdown-item" i18n>Bizops Dashboard</a>
              <div class="dropdown-divider"></div>
              <a (click)="logout()" class="dropdown-item" i18n>Logout</a>
            </div>
          </li>
        </ul>
    </button>
    <ul class="navbar-nav language-selector">
      <li dropdown class="language-selector__dropdown">
        <a class="nav-link" dropdownToggle><i class="fa fa-globe" aria-hidden="true"></i>{{ selectedLang ? selectedLang.name : '' }}</a>
        <ul *dropdownMenu class="dropdown-menu" aria-labelledby="languageMenu">
          <li *ngFor="let lang of supportedLangs">
            <a (click)="selectLanguage(lang.key)">{{ lang.name }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>
