import { filter } from 'rxjs/operators';
// import * as Debug_ from 'debug';
// const Debug = Debug_;
// const debug = Debug('shared:CurUserService');

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { setUser } from '@sentry/browser';

import { Subject, BehaviorSubject } from 'rxjs';

import { User } from '@shared/services/user';
import { setAuthToken } from './transact.backend';
import { AccountFunds } from '../types/account_funds';
import { InvisibleCaptchaService } from './invisible-captcha.service';

const baseUrl = '/api/user/';

@Injectable({
  providedIn: 'root',
})
export class CurUserService {
  curUser: User; // use the convention undefined is not initalized yet,  User.id == 0 is not logged in.
  funds = new AccountFunds();

  // Observable  sources
  curUserUpdatedSource = new BehaviorSubject<User>(undefined);
  curUserFundsUpdatedSource = new Subject<AccountFunds>();
  curUser$ = this.curUserUpdatedSource.asObservable();

  lastUpdatedTimestamp: number;

  private redirectCount = 0;
  private curUserUpdatedTimestamp = 0;

  constructor(
    private http: HttpClient,
    private router: Router,
    private captchaService: InvisibleCaptchaService
  ) {
    this.lastUpdatedTimestamp = Date.now();
    // debug('CurUserService Config:', Config);
    this.loadCurUser();
  }

  // Observable string streams
  get curUserUpdated$() {
    return this.curUserUpdatedSource.asObservable();
  }
  get curUserFundsUpdated$() {
    return this.curUserFundsUpdatedSource.asObservable();
  }
  set shouldLoadFunds(val: boolean) {
    if (!val) {
      return;
    }
    this.loadCurUserFunds();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }

  // this can be used as route gaurd
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let resolveFn: any;
    let rejectFn: any; // setup callbacks
    // create a new Promise we can return, for outside caller to wait on
    const promise = new Promise<boolean>((resolve: any, reject: any) => {
      resolveFn = resolve;
      rejectFn = reject;
    });

    // if curUser has been checked at least once
    this.getUser()
      .then((usr) => {
        // got the User Object, check if this user can activate route
        const ok = this.curUserCanActivateRoute(route, state);
        if (!ok) {
          console.error('Rejected route ', state.url, usr);
        }
        resolveFn(ok);
      })
      .catch((err) => {
        // debug('Error getUser', err);
        console.warn(err);
        resolveFn(false);
      });

    return promise; // return the promise for outside callers to wait on
  }

  // return the current user as a Promise you can wait on.
  getUser(max_age_ms: number = 600000): Promise<User> {
    const cache_age = Date.now() - this.curUserUpdatedTimestamp;
    // debug('getUser cache_age:', cache_age);

    // if we already have current user and is recent enough
    if (this.curUser && cache_age < max_age_ms) {
      // return it immediately
      return Promise.resolve(this.curUser);
    } else if (this.curUser) {
      // needs to be refreshed
      // debug('refreshing cached user', cache_age);
      return this.loadCurUser();
    } else {
      // subscribe for updated user
      let resolveFn: any;
      let rejectFn: any; // setup callbacks
      const promise = new Promise<User>((resolve: any, reject: any) => {
        resolveFn = resolve;
        rejectFn = reject;
      });

      this.curUserUpdated$.pipe(filter((user) => user !== undefined)).subscribe((usr) => {
        resolveFn(usr); // resolve promise for outside callers
      });
      return promise; // return the promise for outside callers to wait on
    }
  }

  // This method forces a request to the API to fetch the current user
  // it returns the current user as a promise, it will also
  // update the subscription event to anyone subscribed
  loadCurUser(): Promise<User> {
    //    debug('CurUserService:loadCurUser ');
    return this.getCurrentUser()
      .catch((err: any) => {
        this.clearCurrentUser();
        return this.curUser;
      })
      .then((usr: any) => {
        this.enshrineNewCurrentUser(usr);
        return this.curUser;
      });
  }

  loadCurUserFunds() {
    this.getCurUserFunds()
      .catch((err) => {
        // debug('cur funds err', err);
        this.curUserFundsUpdatedSource.next(null);
      }) // if not logged in
      .then((funds: AccountFunds) => {
        //        debug('CurUserService funds', funds);

        if (!funds) {
          return;
        }

        this.funds = funds;
        console.log('funds', funds);
        this.curUserFundsUpdatedSource.next(funds);
      });
  }

  getUserFunds(): Promise<AccountFunds> {
    return this.http.get<AccountFunds>(baseUrl + 'tokens').toPromise();
  }

  auth(email: string, password: string, stay_logged_in: boolean, token?: string): Promise<User> {
    const self = this;
    const post_data = {
      email,
      password,
      stay_logged_in: stay_logged_in.toString(),
      token: token ? token : null,
    };

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    this.initLocalData(); // reset when authenticating new user
    return this.http
      .post(baseUrl + 'authenticate', post_data, httpOptions)
      .toPromise()
      .then((response: HttpResponse<any>) => {
        const authorization = response.headers.get('Authorization');
        setAuthToken(authorization, stay_logged_in);

        const usr: User = response.body;
        //        debug('CurUserService auth user:', usr);

        this.registerSentryUser(usr);
        self.curUser = new User(usr);
        self.curUserUpdatedSource.next(usr);
        self.updateCurrentUser(usr);
        self.shouldLoadFunds = true;
        return usr;
      })
      .catch((err: any) => {
        // debug('CurUserService catch err:', err);
        self.initLocalData();
        throw err; // chain throw error to caller
      });
  }

  // Public wrapper for 'register' that returns whatever promise the API call yielded without subsequent post-processing.
  public tryToRegisterNewUser(new_user: User, captcha?: string): Promise<User> {
    return this.register(new_user, captcha);
  }

  //  Timestamp to be used for URLs that need to change but don't really change
  //    can't use straight getTime - causes [src] update errors - too frequent
  public verifyEmail(token: string): Promise<any> {
    const post_data = {
      token,
    };

    return this.http.post(baseUrl + 'email/verify', post_data).toPromise();
  }

  logout(goHome?: boolean): Promise<any> {
    return this.http
      .get(`${baseUrl}logout`)
      .toPromise()
      .then(
        () => {
          this.clearSession(goHome);
        },
        (err) => {
          console.error(err);
        }
      );
  }

  clearSession(goHome: boolean) {
    sessionStorage.removeItem('authorization');
    localStorage.removeItem('authorization');

    this.curUser = new User();
    this.curUser.id = 0;
    this.curUserUpdatedSource.next(this.curUser);
    if (goHome) {
      this.navigateRoute('/');
    }
  }

  isLoggedIn(): boolean {
    if (this.curUser === undefined) {
      return undefined;
    }
    return this.curUser.id !== 0;
  }

  public updateUserProfile(fieldsHashToSave: any = {}): Promise<User> {
    const promise = this.http
      .post<User>(this.makeEndpointUrl('profile'), fieldsHashToSave)
      .toPromise()
      .then((updatedUserHash: User) => {
        // Tricky. Is it a proper assumption that the user we're working on is currentUser? No. A common case might
        // be editing other users, perhaps in admin interface.
        // Could compare id of user being updated to currentUser. But currently API assumes a profile
        // change is ALWAYS currentUser. So this assumption has been made elsewhere. Roll with that.
        const updatedUser = this.enshrineNewCurrentUser(updatedUserHash);
        this.shouldLoadFunds = true;
        return Promise.resolve(updatedUser); // return promise
      });

    return promise;
  }

  // Must pass in: new_password, old_password
  public updateUserPassword(fieldsHashToSave: any = {}): Promise<unknown> {
    const post_data: any = {};

    Object.keys(fieldsHashToSave).map((key) => {
      post_data[key] = fieldsHashToSave[key];
    });

    const promise = this.http.post(this.makeEndpointUrl('password/change'), post_data).toPromise();

    return promise;
  }

  public enshrineNewCurrentUser(newUser: any): User {
    // debug('CurUserService newUser', typeof newUser, newUser);

    if (!(newUser instanceof User)) {
      newUser = new User(newUser);
    }

    this.updateCurrentUser(newUser);

    return newUser;
  }

  async fundsChanged(funds: AccountFunds): Promise<boolean> {
    const countLimit = 10;
    let count = 0;
    const checkFunds = () => {
      return this.getUserFunds().then((userFunds: AccountFunds) => {
        return (!this.areObjectsEqual(funds, userFunds))
      });
    };

    while (count < countLimit) {
      if (await checkFunds()) {
        return true;
      }
      count++;
      await new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
    }

    return false;
  }

  private initLocalData() {
    this.curUser = new User();
    this.funds = new AccountFunds();
    this.curUserUpdatedTimestamp = 0;
    this.redirectCount = 0;
    this.curUserUpdatedSource.next(this.curUser);
  }
  // Fill with current user data
  private getCurrentUser(): Promise<User> {
    const self = this;

    return this.http
      .get<User>(baseUrl + 'profile')
      .toPromise()
      .then((response: User) => {
        if (response.id) {
          const usr: User = response;
          this.registerSentryUser(usr);

          return usr;
        } else {
          //          debug('getCurrentUser NOT logged status:', response);
          return new User();
        }
      })
      .catch((err: HttpErrorResponse) => {
        // debug('getCurrentUser NOT logged in', err);
        self.initLocalData();
        return new User();
      });
  }

  private registerSentryUser(user: User) {
    setUser({
      email: user.email1,
      id: user.id + '',
    });
  }

  private getCurUserFunds(): Promise<AccountFunds> {
    return this.http.get<AccountFunds>(baseUrl + 'tokens').toPromise();
  }

  private register(new_user: User, captcha?: string): Promise<User> {
    // debug('register new user:', new_user);
    const self = this;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    let captchaPromise: Promise<string | null>;
    if (captcha) {
      headers = headers.set('greCaptcha', captcha);
      captchaPromise = Promise.resolve(null);
    } else {
      captchaPromise = this.captchaService.tryExecute();
    }

    return captchaPromise.then((result) => {
      if (result) {
        headers = headers.set('gre-invisible', result);
      }

      const httpOptions = {
        headers,
        observe: 'response' as 'response',
      };

      return this.http
        .post<User>(baseUrl + 'create', new_user, httpOptions)
        .toPromise()
        .then((response: HttpResponse<any>) => {
          const authorization = response.headers.get('Authorization');
          console.log('auth token', authorization);
          setAuthToken(authorization, false);

          const usr: User = response.body;
          this.registerSentryUser(usr);
          self.curUser = new User(usr);
          self.curUserUpdatedSource.next(usr);
          self.updateCurrentUser(usr);
          self.shouldLoadFunds = true;
          return usr;
        })
        .catch((err: any) => {
          // debug('CurUserService catch err:', err);
          self.initLocalData();
          throw err; // chain throw error to caller
        });
    });
  }

  private curUserCanActivateRoute(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.curUser) {
      throw new Error('BUG: Should only call this if curUser is set');
    }

    //    debug('CurUserService:canActivateRoute ', this.curUser)

    if (this.curUser.id === 0) {
      return this.notLoggedInCanActivate(route, state);
    }

    if (this.curUser.staff) {
      return true;
    }

    // user is logged in
    if (
      state.url.startsWith('/login') ||
      state.url.startsWith('/signup') ||
      state.url.startsWith('/reset_password')
    ) {
      if (this.curUser.seller) {
        this.navigateRoute('/publisher/summary');
      } else {
        this.navigateRoute('/user/dashboard');
      }

      return false;
    }

    if (!this.curUser.seller && state.url.startsWith('/publisher')) {
      // not allowed to be in the publisher realm
      if (this.curUser.buyer) {
        this.navigateRoute('/user/dashboard');
      } else if (this.curUser.staff) {
        console.log('route staff');
        document.location.assign('/bizops');
      } else {
        console.error('ERROR logged in, but not buyer', this.curUser);
      }
      return false;
    }

    return true; // default
  }

  private notLoggedInCanActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (
      (state.url.startsWith('/user/subscription/') && state.url.indexOf('redeem') < 0) ||
      state.url.startsWith('/user/fund_account') ||
      state.url.startsWith('/user/subscriptions')
    ) {
      console.log('redirect to signup');
      this.router.navigate(['/signup'], { queryParams: { returnUrl: state.url } });
      return true;
    }

    if (state.url.startsWith('/user/subscription/gift/redeem/')) {
      return true;
    }

    if (
      state.url.startsWith('/user/') ||
      state.url.startsWith('/bizops/') ||
      state.url.startsWith('/publisher/')
    ) {
      // debug('not logged in, and in a area that requires auth, redirect to /');
      this.navigateRoute('/');
      return false;
    }

    return true;
  }
  private navigateRoute(path: string) {
    this.redirectCount += 1;
    // debug('redirect to: "' + path + '"  count:' + this.redirectCount.toString());

    if (this.redirectCount < 50) {
      this.router.navigate([path]);
    } else {
      console.error('Over Max redirets', path, this.redirectCount);
    }
  }

  private updateCurrentUser(usr: User) {
    this.curUser = new User(usr);
    this.curUserUpdatedSource.next(usr);
    this.curUserUpdatedTimestamp = Date.now();
  }
  private clearCurrentUser(): void {
    this.curUser = new User();
    this.curUserUpdatedSource.next(this.curUser);
    this.curUserUpdatedTimestamp = 0;
  }

  private makeEndpointUrl(
    stringToAppend: any = '',
    extraStringToAppend: any = '',
    isCacheBust: boolean = false
  ): string {
    let urlEndpoint = baseUrl;

    if (stringToAppend && stringToAppend.length) {
      urlEndpoint = urlEndpoint + stringToAppend.toString();

      // This allows a url *value* to be kept separate from the base endpoint.
      if (extraStringToAppend && extraStringToAppend.length) {
        urlEndpoint = urlEndpoint + extraStringToAppend.toString();
      }

      if (isCacheBust) {
        urlEndpoint = urlEndpoint + '?c=' + Date.now();
      }
    }
    return urlEndpoint;
  }

  private areObjectsEqual(object1: any, object2: any) {
    // value , other
    const type = Object.prototype.toString.call(object1);
    if (type !== Object.prototype.toString.call(object2)) {
      return false;
    }
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) {
      return false;
    }

    const valueLen = type === '[object Array]' ? object1.length : Object.keys(object1).length;
    const otherLen = type === '[object Array]' ? object2.length : Object.keys(object2).length;
    if (valueLen !== otherLen) {
      return false;
    }

    const compare = (item1: any, item2: any) => {
      const itemType = Object.prototype.toString.call(item1);
      if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
        if (!this.areObjectsEqual(item1, item2)) {
          return false;
        }
      } else {
        if (itemType !== Object.prototype.toString.call(item2)) {
          return false;
        }
        if (itemType === '[object Function]') {
          if (item1.toString() !== item2.toString()) {
            return false;
          }
        } else {
          if (item1 !== item2) {
            return false;
          }
        }
      }
    };
    if (type === '[object Array]') {
      for (let i = 0; i < valueLen; i++) {
        if (compare(object1[i], object2[i]) === false) {
          return false;
        }
      }
    } else {
      for (const key in object1) {
        if (object1.hasOwnProperty(key)) {
          if (compare(object1[key], object2[key]) === false) {
            return false;
          }
        }
      }
    }
    return true;
  }
}
