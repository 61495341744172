<div *ngIf="!showAllCurrencies" class="currency-number large">
  {{ fundsAll.default_total | xsactCurrency:fundsAll.default_currency:'hundredth' }}
</div>
<div class="btn-group" *ngIf="showAllCurrencies && fundsAll" dropdown [isDisabled]="(funds | keyvalue).length <= 1">
  <button type="button" class="currency-number large dropdown-toggle" dropdownToggle aria-haspopup="true" aria-expanded="false">
    {{ funds[selectedCurrency] | xsactCurrency:selectedCurrency:'hundredth' }}
  </button>
  <ng-container *ngIf="(funds | keyvalue).length > 1">
    <ul class="dropdown-menu" *dropdownMenu role="menu">
      <ng-container *ngFor="let currency of funds | keyvalue">
        <li *ngIf="TokenClass[currency.key] && currency.key != TokenClass.TEST">
          <a class="currency-number" href="javascript:void(0);" (click)="selectCurrency(currency.key)">
            {{ funds[currency.key] | xsactCurrency:currency.key:'hundredth' }}
          </a>
        </li>
      </ng-container>
    </ul>
  </ng-container>
</div>
