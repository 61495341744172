import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Transaction } from '../../services/transaction';

const defaultOptions = {
  displayFormat: '',
  maxTransactions: 50,
  limitToRecentDays: 9999,
  excludeRecentDays: 0,
  noItemsMessage: 'No matches.',
};

@Component({
    selector: 'app-elements-transaction-list',
    templateUrl: 'elements-transaction-list.component.html',
    styleUrls: ['../elements.scss', 'elements-transaction-list.component.scss'],
    standalone: false
})
export class ElementsTransactionListComponent implements OnChanges {
  @Input() transactionList: Array<Transaction> = new Array();

  // Settings governing how list container is rendered.
  @Input() optionsForList: any = {};

  // Passthrough settings that end up in 'ElementsTransactionSingleComponent'.
  @Input() optionsForSingle: any = {};

  transactionsToDisplay: Transaction[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.optionsForList || changes.transactionList) {
      // this.transactionsToDisplay = this.getTransactions();
    }
  }

  transactionTrackBy(index: number, transaction: Transaction) {
    return transaction.id;
  }

  // Lookup some property value from passed defaults hash.
  getTransactions(debugTxt: string = 'none'): Array<Transaction> {
    if (!this.transactionList || this.transactionList.length === 0) {
      return [];
    }

    let finalList = this.transactionList;

    // console.info('opts', this.getOption('limitToRecentDays'), this.getOption('maxTransactions'));
    // console.info(`finalList (${debugTxt})`, finalList, this.transactionList, this.optionsForList);

    if (this.getOption('excludeRecentDays')) {
      finalList = finalList.filter((transaction) => {
        const excludeDayCount = parseInt(this.getOption('excludeRecentDays'), 10);
        return Date.now() - transaction.timestamp > excludeDayCount * 24 * 60 * 60 * 1000;
      });
    }

    if (this.getOption('limitToRecentDays')) {
      finalList = finalList.filter((transaction) => {
        const withinDayCount = parseInt(this.getOption('limitToRecentDays'), 10);
        return Date.now() - transaction.timestamp < withinDayCount * 24 * 60 * 60 * 1000;
      });
    }

    // Sort reverse chronologically (for now).
    finalList = finalList.sort((leftSide, rightSide): number => {
      if (leftSide.timestamp < rightSide.timestamp) {
        return 1;
      }
      if (leftSide.timestamp > rightSide.timestamp) {
        return -1;
      }
      return 0;
    });

    // Make sure to SORT before chopping off the relevant subset.
    if (this.getOption('maxTransactions')) {
      finalList = finalList.slice(0, parseInt(this.getOption('maxTransactions'), 10));
    }

    return finalList;
  }

  // Lookup some property value from passed defaults hash.
  getOption(optionToGet: string): string {
    const localDefault = (defaultOptions as any)[optionToGet];
    const optionsHashToUse = this.optionsForList;
    const passedOption = optionsHashToUse[optionToGet];
    let returnValue = '';

    if (typeof localDefault === 'undefined') {
      console.error(
        `Requested option ${optionToGet} for 'elements-transaction-list' is INVALID.`,
        defaultOptions
      );
    } else if (passedOption) {
      returnValue = passedOption;
    } else {
      returnValue = localDefault;
    }

    return returnValue;
  }

  goToUrl(transaction: any) {
    if (transaction.url) {
      window.open(transaction.url, '_blank');
    }
  }
}
