<div class="contact-form">
  <h1 i18n>Send a Message</h1>

  <div [hidden]="!success" class="alert alert-success" i18n>Thank you for your message! One of your staff will be in touch shortly.
    <span class="pull-right tt__cursor-pointer" (click)="clearSuccessMessage()"><strong>&times;</strong></span>
  </div>
  <div class="form-well" [hidden]="success">
    <app-validated-input class="alert-style" [errors]="errorMessages">
      <app-validation-message i18n displayOn="TIMEOUT">Failed to send the message, please try again.</app-validation-message>
      <app-validation-message i18n displayOn="*">Sorry, an error occured. If the problem persists please call our customer service line.</app-validation-message>
    </app-validated-input>
    <form [formGroup]="contactForm" (ngSubmit)="submit(contactForm, contactForm.valid)" class="">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="email" class="formHeading" i18n>Email Address</label>
            <input id="email" type="email"  autocorrect="off" autocapitalize="off"
              autocomplete="email" placeholder="example@example.com"
              class="form-control" formControlName="email">
            <app-validated-input class="tooltip-style" [errors]="contactForm.get('email').errors" [hidden]="contactForm.get('email').pristine && !submitted">
              <app-validation-message displayOn="required" i18n>Email is required.</app-validation-message>
              <app-validation-message displayOn="invalidEmailAddress" i18n>Must be a valid email.</app-validation-message>
            </app-validated-input>
          </div>
        </div>
        <div class="col-md-6">
          <label for="phone" class="formHeading" i18n>Phone Number</label>
          <app-xsact-phone-number [initial]="contactForm.get('phone').value" (phone)="onPhoneNumber($event)" (country)="onCurrentCountry($event)"></app-xsact-phone-number>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="company_name" class="formHeading" i18n>Company Name</label>
            <input id="company_name" type="text" class="form-control" formControlName="company_name">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="company_website" class="formHeading" i18n>Company Website</label>
            <input id="company_website" type="text" class="form-control" formControlName="company_website">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="subject" class="formHeading" i18n>Subject</label>
            <div class="row">
              <div class="input-group col-md-4">
                <select id="subject" class="custom-select form-control" formControlName="subject">
                  <option value="" i18n>Select a Subject</option>
                  <ng-content></ng-content>
                  <option value="Suggestion or Idea" i18n>Suggestion or Idea</option>
                  <option value="Bug report" i18n>Bug report</option>
                  <option value="General Question" i18n>General Question</option>
                  <option value="other" i18n>Other</option>
                </select>
                <app-validated-input class="tooltip-style" [errors]="contactForm.get('subject').errors" [hidden]="contactForm.get('subject').pristine && !submitted">
                  <app-validation-message displayOn="required" i18n>Subject is required.</app-validation-message>
                </app-validated-input>
              </div>
              <div class="col-md-8" *ngIf="contactForm.get('subject').value === 'other'">
                <input id="subject_other" type="text" class="form-control" formControlName="subject_other" required>
                <app-validated-input class="tooltip-style" [errors]="contactForm.get('subject_other').errors" [hidden]="contactForm.get('subject_other').pristine && !submitted">
                  <app-validation-message displayOn="required" i18n>Subject is required.</app-validation-message>
                </app-validated-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <div class="form-group">
            <label for="message" class="formHeading" i18n>Message</label>
            <textarea id="message" type="text" class="form-control" formControlName="message"></textarea>
            <app-validated-input class="tooltip-style" [errors]="contactForm.get('message').errors" [hidden]="contactForm.get('message').pristine && !submitted">
              <app-validation-message displayOn="required" i18n>Message is required.</app-validation-message>
            </app-validated-input>
          </div>
        </div>
      </div>
      <div class="button-separation row">
        <div class="col-sm-12">
          <button class="tt--button button--blue large-2x pull-right" id="contact_send" i18n>Send</button>
        </div>
      </div>
    </form>
  </div>
</div>
