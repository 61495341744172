<footer class="footer container">
  <div class="logo-copyright">
    <a [routerLink]="['/landing']">
      <img alt="Transact.io" src="/assets/images/transact_logo.png" />
    </a>
    <p class="copy" i18n>Copyright &copy; 2025 xsact, inc. All Rights Reserved.</p>
  </div>
  <ul class="links">
    <li><a routerLink="/about/" i18n>About Us</a></li>
    <li><a routerLink="/about/contact" i18n>Contact Us</a></li>
    <li><a routerLink="/about/terms" i18n>Terms of Use</a></li>
    <li><a routerLink="/about/privacy" i18n>Privacy Policy</a></li>
    <li><a routerLink="/about/security" i18n>Security</a></li>
    <li><a routerLink="/about/integration" i18n>Integration</a></li>
  </ul>
  <span class="version-app"> Updated: {{ buildDate }}</span>
  <span class="version-git"> {{ gitHash }}</span>
</footer>
