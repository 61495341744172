import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { UtilityService } from '../index';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ModalNotificationMessage } from '../types/modal_notification_message';

export interface ErrorMap {
  [key: string]: boolean;
}

@Component({
    selector: 'app-xsact-body',
    templateUrl: 'xsact-body.component.html',
    styleUrls: ['xsact-body.component.scss'],
    standalone: false
})
export class XsactBodyComponent implements OnInit {
  @ViewChild('appNotificationModalWrapper', { read: TemplateRef }) appModal: TemplateRef<void>;
  appNotificationModal: BsModalRef;

  appModalData: ModalNotificationMessage;

  constructor(private utilitySvc: UtilityService, private modalService: BsModalService) {}

  ngOnInit() {
    if (!navigator.cookieEnabled) {
      this.utilitySvc.showNotificationBar(
        'Transact and sites you purchase content from may not work properly with cookies disabled.',
        'cookieDisabledMessage'
      );
    }

    this.utilitySvc.appNotificationModal.subscribe((result: ModalNotificationMessage) => {
      if (result) {
        this.appModalData = result;
        this.appNotificationModal = this.modalService.show(this.appModal, { class: 'modal-lg' });
      }
    });
  }
}
