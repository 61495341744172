import { Component, OnInit, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { PhoneCodes, PhoneInfo } from '../services/country-phone-codes';

const phoneNumberExpr = '^(?:(?:\\(?(?:00|\\+)([1-4]\\d\\d|[1-9]\\d?)\\)?)?[\\-\\.\\ \\\\\\/]?)?' +
    '((?:\\(?\\d{1,}\\)?[\\-\\.\\ \\\\\\/]?){0,})(?:[\\-\\.\\ \\\\\\/]?(?:#|ext\\.?|extension|x)[\\-\\.\\ \\\\\\/]?(\\d+))?$';
const phoneNumberRegex = new RegExp(phoneNumberExpr);

@Injectable()
export class PhoneValidation {
    static phoneValidator(control: FormControl) {
        if (!control.value) {
            return null;
        } else {
            if (control.value.match(phoneNumberRegex)) {
            return null;
            } else {
            return { invalidPhoneNumber: true };
            }
        }
    }
}

/**
 * This class represents the lazy loaded dashboardComponent.
 */
@Component({
    selector: 'app-xsact-phone-number',
    templateUrl: 'phone-number.component.html',
    styleUrls: ['phone-number.component.scss'],
    providers: [FormBuilder, PhoneCodes, PhoneValidation],
    standalone: false
})
export class PhoneNumberComponent implements OnInit {

    @Input() initial: string;
    @Output() phone = new EventEmitter<string>();
    @Output() country = new EventEmitter<PhoneInfo>();
    phoneForm: FormGroup;
    currentCountry: PhoneInfo;
    countryPhoneCodes: PhoneInfo[];

    constructor(private fb: FormBuilder, private cPhoneCodes: PhoneCodes) {
    }

    ngOnInit() {
        this.countryPhoneCodes = this.cPhoneCodes.countryCallingCodes;
        this.currentCountry = this.cPhoneCodes.usDefault;
        this.country.emit(this.currentCountry);
        this.phoneForm = this.fb.group({
            phoneNumber: [this.initial,  PhoneValidation.phoneValidator]
        });
    }

    public pickCountry(evt: any) {
        evt.preventDefault();
        this.currentCountry = this.countryPhoneCodes.filter(c => c.iso2 === evt.target.value)[0];
        this.country.emit(this.currentCountry);
    }

    public phoneNumberChange(evt: Event) {
        evt.preventDefault();
        this.phone.emit(this.phoneForm.get('phoneNumber').value);
    }
}
