<form [formGroup]="phoneForm">
    <div class="d-none d-sm-flex input-group">
        <select class="custom-select" (change)="pickCountry($event)">
            <option
                *ngFor="let choice of countryPhoneCodes" [value]="choice.iso2">{{ choice.name }}</option>
        </select>
        <span class="form-control country-prefix">+{{ currentCountry.dialCode }}</span>
        <input id="phone"
           type="text"
           class="form-control phone-number"
           formControlName="phoneNumber"
           (change)="phoneNumberChange($event)"
           placeholder="555-555-5555"
           aria-describedby="basic-addon1">
    </div>
    <div class="d-sm-none">
        <div class="input-group">
            <select class="custom-select" (change)="pickCountry($event)">
                <option
                    *ngFor="let choice of countryPhoneCodes" [value]="choice.iso2">{{ choice.name }}</option>
            </select>
        </div>
        <div class="input-group">
            <span class="form-control country-prefix">+{{ currentCountry.dialCode }}</span>
            <input id="phone"
            type="text"
            class="form-control phone-number"
            formControlName="phoneNumber"
            (change)="phoneNumberChange($event)"
            placeholder="555-555-5555"
            aria-describedby="basic-addon1">
        </div>
    </div>
    <!-- <div class="country-dropdown-holder" dropdown (isOpenChange)="toggledCountryDropdown($event)" id="basic-addon1">
        <a href dropdownToggle (click)="false">{{ currentCountry.name }}
            <span class="caret"></span>
        </a>
        <ul *dropdownMenu class="dropdown-menu">
            <li *ngFor="let choice of countryPhoneCodes">
                <a class="dropdown-item" (click)="pickCountry($event, choice)">{{ choice.name }}</a>
            </li>
        </ul>
    </div>
    <span class="country-number">+{{ currentCountry.dialCode }}</span>
    <input id="phone"
           type="text"
           class="tt__input"
           formControlName="phoneNumber"
           (change)="phoneNumberChange($event)"
           placeholder="555-555-5555"
           aria-describedby="basic-addon1"> -->
    <app-validated-input class="tooltip-style" [errors]="phoneForm.get('phoneNumber').errors" [hidden]="phoneForm.get('phoneNumber').pristine">
        <app-validation-message displayOn="invalidPhoneNumber" i18n>Must be a valid phone number.</app-validation-message>
    </app-validated-input>
</form>
