import {
  Component,
  Input,
  Output,
  EventEmitter,
  Host,
  AfterContentInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { XsactLeftNavComponent } from './xsact-left-nav.component';

/**
 * This class represents the navigation bar component.
 */
@Component({
    selector: 'app-xsact-left-nav-link',
    templateUrl: 'xsact-left-nav-link.component.html',
    standalone: false
})
export class XsactLeftNavLinkComponent implements AfterContentInit, OnChanges {
  @Input() rlink: string;
  @Input() icon: string;
  @Input() svg: string;
  @Output() flink: EventEmitter<any> = new EventEmitter();
  public selected: boolean;

  private parentNavigation: XsactLeftNavComponent;

  constructor(@Host() parentNavigation: XsactLeftNavComponent) {
    this.selected = false;

    parentNavigation.routeChange.subscribe((route: string) => {
      this.selected = this.rlink === route;
    });

    this.parentNavigation = parentNavigation;
  }

  ngAfterContentInit() {
    this.selected = this.rlink === this.parentNavigation.currentRoute;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rlink && changes.rlink.currentValue) {
      this.selected = this.rlink === this.parentNavigation.currentRoute;
    }
  }

  linkClicked(): void {
    this.parentNavigation.collapse();

    if (this.flink) {
      this.flink.emit(null);
    }
  }
}
