import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

/* FIXME
// import { Ng2UploaderModule } from 'ng2-uploader'; FIXME
*/

import { XsactCreditCardComponent } from './xsact-credit-card/index';

import { UserService } from './services/user.service';
import { GroupService } from './services/group.service';
import { DiscountCodesService } from './services/discount-codes.service';

import { CapitalizePipe } from './pipes/capitalize.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { XsactDatePipe } from './pipes/xsact-datePipe';
import { XsactTokensToUSDPipe } from './pipes/xsact-tokensToUSDPipe';
import { LoginHeadersFilterPipe } from './pipes/login-headers.pipe';

// Shared elements
import { ElementsSpinnerComponent } from './elements/elements-spinner/elements-spinner.component';
import { ElementsTransactionSingleComponent } from './elements/elements-transaction-single/elements-transaction-single.component';
import { ElementsTransactionListComponent } from './elements/elements-transaction-list/elements-transaction-list.component';

import { ElementsAuditLogComponent } from './elements/elements-audit-log/elements-audit-log.component';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DiscountCodesComponent } from './discount-codes/discount-codes.component';
import { ActivationCodeComponent } from './discount-codes/code-activation/code-activation.component';
import { SubscriptonsLineChartComponent } from './charts/subscriptions-line-chart/subscriptions-line-chart.component';
import { SubscriptionsPieChartComponent } from './charts/subscription-pie-chart/subscriptions-pie-chart.component';
import { PublisherLogoComponent } from './publisher-banner-image/publisher-banner-image.component';

// import { FormElementsAddressHorizontalComponent }
// from './form-elements/form-elements-address-horizontal/form-elements-address-horizontal.component';
// import { XctFormElementsModule } from './form-elements/form-elements.module';

import { XsactBodyComponent } from './xsact-body/index';
import { XsactFooterComponent } from './xsact-footer/index';
import { XsactHeaderComponent } from './xsact-header/index';
import { XsactLeftNavComponent, XsactLeftNavLinkComponent } from './xsact-left-nav/index';

import { ContactFormComponent } from './contact-form/index';
import { PhoneNumberComponent } from './phone-number/index';

import { OAuth2Service } from './services/oauth2.service';
import { ImageService } from './services/image.service';

import { StateService, CountryService } from './index';
import { PhoneCodes } from './index';
import { UtilityService } from './services/utility';

import {
  FormElementsButtonEditComponent,
  FormElementsChangePasswordComponent,
  FormElementsDocumentComponent,
  FormElementsFieldErrorMessageComponent,
  FormElementsSavingMessageComponent,
  FormElementsUserNameAndEmailComponent,
  TwoFactorAuthSettingsComponent,
  LoadingButtonComponent,
  ValidateEqualDirective,
  ValidatedInputComponent,
  ValidationMessageComponent,
} from './form-elements/index';

export const TRANSACT_FORM_ELEMENTS: any[] = [
  FormElementsButtonEditComponent,
  FormElementsChangePasswordComponent,
  FormElementsDocumentComponent,
  FormElementsFieldErrorMessageComponent,
  FormElementsSavingMessageComponent,
  FormElementsUserNameAndEmailComponent,
  TwoFactorAuthSettingsComponent,
  LoadingButtonComponent,
  ValidateEqualDirective,
  ValidatedInputComponent,
  ValidationMessageComponent,
];

import { DirectivesModule } from '@shared/directives/directives.module';

// karl: The other way to handle ngx-bootstrap  just import what we use.
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { CurrencySelectorComponent } from './currency-selector/currency-selector.component';
import { RecaptchaComponent } from './recaptcha/recaptcha.component';
import { ToastModule } from './toast/toast.module';

export const TRANSACT_NGX_BOOTSTRAP_ELEMENTS: any[] = [
  AlertModule,
  CollapseModule,
  BsDatepickerModule,
  TimepickerModule,
  BsDropdownModule,
  ModalModule,
  PopoverModule,
  TooltipModule,
];

/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    //    Ng2UploaderModule,  FIXME
    ReactiveFormsModule,
    RouterModule,
    DirectivesModule,
    BaseChartDirective,

    TRANSACT_NGX_BOOTSTRAP_ELEMENTS,
    AlertModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    ToastModule,
  ],
  declarations: [
    TRANSACT_FORM_ELEMENTS,

    CapitalizePipe,
    TruncatePipe,
    XsactCreditCardComponent,
    XsactDatePipe,
    LoginHeadersFilterPipe,
    XsactTokensToUSDPipe,
    ElementsSpinnerComponent,
    ElementsTransactionListComponent,
    ElementsTransactionSingleComponent,
    ElementsAuditLogComponent,
    XsactBodyComponent,
    XsactFooterComponent,
    XsactHeaderComponent,
    XsactLeftNavComponent,
    XsactLeftNavLinkComponent,
    ContactFormComponent,
    PhoneNumberComponent,
    NotificationBarComponent,
    FileUploadComponent,
    DiscountCodesComponent,
    ActivationCodeComponent,
    SubscriptonsLineChartComponent,
    SubscriptionsPieChartComponent,
    PublisherLogoComponent,
    CurrencySelectorComponent,
    RecaptchaComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ToastModule,

    TRANSACT_FORM_ELEMENTS,

    CapitalizePipe,
    TruncatePipe,
    XsactCreditCardComponent,
    XsactDatePipe,
    XsactTokensToUSDPipe,
    LoginHeadersFilterPipe,

    ElementsSpinnerComponent,
    ElementsTransactionListComponent,
    ElementsTransactionSingleComponent,
    ElementsAuditLogComponent,
    NotificationBarComponent,
    FileUploadComponent,
    DiscountCodesComponent,
    ActivationCodeComponent,
    SubscriptonsLineChartComponent,
    SubscriptionsPieChartComponent,

    XsactBodyComponent,
    XsactFooterComponent,
    XsactHeaderComponent,
    XsactLeftNavComponent,
    XsactLeftNavLinkComponent,
    ContactFormComponent,
    PhoneNumberComponent,
    // TRANSACT_NGX_BOOTSTRAP_ELEMENTS,
    DirectivesModule,
    PublisherLogoComponent,
    CurrencySelectorComponent,
    RecaptchaComponent,
  ],
  providers: [OAuth2Service, StateService, CountryService, PhoneCodes,provideCharts(withDefaultRegisterables())],
})
export class XsactSharedModule {
  static forRoot(): ModuleWithProviders<XsactSharedModule> {
    return {
      ngModule: XsactSharedModule,
      providers: [
        UserService,
        GroupService,
        DiscountCodesService,
        CapitalizePipe,
        ImageService,
        TruncatePipe,
        XsactCreditCardComponent,
        XsactDatePipe,
        XsactTokensToUSDPipe,
        LoginHeadersFilterPipe,
        UtilityService,

        ElementsSpinnerComponent,
        ElementsTransactionListComponent,
        ElementsTransactionSingleComponent,
        ElementsAuditLogComponent,
        NotificationBarComponent,
        FileUploadComponent,
        DiscountCodesComponent,
        ActivationCodeComponent,
        SubscriptonsLineChartComponent,
        SubscriptionsPieChartComponent,
      ],
    };
  }
}
