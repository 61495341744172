import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CurUserService } from '../services/cur-user.service';
import { ContactService } from '../services/contact.service';
import { PhoneInfo } from '../services/country-phone-codes';
import { XctFormValidationService, ErrorMap } from '../services/form_validation.service';

/**
 * This class represents the lazy loaded dashboardComponent.
 */
@Component({
    selector: 'app-contact-form',
    templateUrl: 'contact-form.component.html',
    styleUrls: ['contact-form.component.scss'],
    providers: [FormBuilder, ContactService, XctFormValidationService],
    standalone: false
})
export class ContactFormComponent implements OnInit {
  contactForm: FormGroup;
  router: Router;
  submitted = false;
  success = false;
  currentCountry: PhoneInfo;
  phoneNumber: string;

  errorMessages: ErrorMap = {};

  constructor(
    private fb: FormBuilder,
    private currUsrSvc: CurUserService,
    private contactSvc: ContactService,
    router: Router
  ) {
    this.router = router;
  }

  /**
   * Get the names OnInit
   */
  ngOnInit() {
    this.setFormValues();
  }

  goToSection(evt: Event, s: string) {
    console.log('goToSection user-dashboard', evt, s);
    evt.preventDefault();
    const e = document.getElementById(s);
    if (!!e && e.scrollIntoView) {
      e.scrollIntoView();
    }
  }

  submit(form: FormGroup, valid: boolean): boolean {
    this.submitted = true;
    if (!valid) {
      return false;
    } else {
      this.errorMessages = {};
      this.contactSvc.submitContactForm(form.value).then(
        () => {
          this.success = true;
        },
        (err) => {
          this.errorMessages = {};
          const res = err;
          if (res && res.code) {
            this.errorMessages[res.code] = true;
          } else {
            this.errorMessages.ERROR = true;
          }
        }
      );
      return true;
    }
  }

  public clearSuccessMessage() {
    const oldValues = this.contactForm.value;
    oldValues.subject = '';
    oldValues.message = '';
    oldValues.subject_other = '';
    this.contactForm.reset(oldValues);
    this.success = false;
    this.submitted = false;
  }

  public setFormValues() {
    this.contactForm = this.fb.group({
      email: [
        this.currUsrSvc.curUser.email1 ? this.currUsrSvc.curUser.email1 : '',
        [Validators.required, XctFormValidationService.emailAddress],
      ],
      phone: [''],
      company_name: [''], // don't load from curPublisherSvc because not worth loading publisher module.
      company_website: [''], // TODO see if we can pass this as input to component

      subject: ['', Validators.required],
      subject_other: [''],
      message: ['', Validators.required],
    });
    this.phoneNumber = this.contactForm.get('phone').value;
  }

  public onCurrentCountry(newValue: PhoneInfo) {
    this.currentCountry = newValue;
  }

  public onPhoneNumber(newValue: string) {
    this.contactForm.patchValue({ phone: '+' + this.currentCountry.dialCode + ' ' + newValue });
  }
}
