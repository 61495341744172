import {
    Directive,
    ElementRef,
    HostListener,
    OnInit,
    Output,
    EventEmitter,
    Input
} from '@angular/core';

export const CurrencySymbolMap = {
    PROD: '$',
    USD: '$',
    EUR: '€',
    YEN: '¥',
    GBP: '£',
    CAD: 'C$',
};

@Directive({
    selector: '[appCurrencyPrice]',
    standalone: false
})
export class CurrencyPriceDirective implements OnInit {
    @Input() set appCurrencyPrice(currency: string) {
        this.currencySymbol = CurrencySymbolMap[currency] || CurrencySymbolMap.USD;
        this.formatCurrency();
    };
    @Output() inputValues = new EventEmitter<Record<string, number>>();

    currencySymbol = CurrencySymbolMap.USD;
    element: any;

    constructor(private el: ElementRef) {
    }

    @HostListener('keyup', ['$event'])
    public onKeyup(event: any) {
        this.formatCurrency(event);
    }

    @HostListener('blur', ['$event'])
    public onBlur(event: any) {
        this.formatCurrency(event);
    }

    ngOnInit() {
        this.element = this.el.nativeElement;
        setTimeout(() => {
            this.element.value = this.changeCentsToDollars(this.element.value);
            this.formatCurrency();
        }, 0);
    }

    changeCentsToDollars(n: any) {
        return n / 100;
    }

    changeDollarsToCents(value: any) {
        const noDollarValue = value ? value.toString().split(this.currencySymbol).join('') : '';
        return Math.round(noDollarValue.split(',').join('') * 100);
    }

    formatCurrency(event?: any) {
        if(!this.element) {
            return;
        }

        let inputValue = this.element.value;

        if (inputValue === '') {
            return;
        }

        const eventType = (event && event.type) ? event.type : 'none';

        if (inputValue.indexOf('.') >= 0) {
            const decimalPos = inputValue.indexOf('.');

            let leftSide = inputValue.substring(0, decimalPos);
            let rightSide = inputValue.substring(decimalPos);

            leftSide = this.formatNumber(leftSide);
            rightSide = this.formatNumber(rightSide);

            if (eventType === 'blur' || eventType === 'none') {
                rightSide += '00';
            }

            rightSide = rightSide.substring(0, 2);
            inputValue = this.currencySymbol + leftSide + '.' + rightSide;
        } else {
            inputValue = this.formatNumber(inputValue);
            inputValue = this.currencySymbol + inputValue;

            if (eventType === 'blur' || eventType === 'none') {
                inputValue += '.00';
            }
        }

        this.element.value = inputValue;
        this.inputValues.emit({
            dollars: this.element.value,
            cents: this.changeDollarsToCents(this.element.value)
        });
    }

    formatNumber(n: any) {
        return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
}
