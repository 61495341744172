import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

const baseUrl = '/api/user/';

@Injectable()
export class ContactService {
  constructor(private http: HttpClient) {

  }

  public submitContactForm(contactForm: any): Promise<any> {

    const params = new HttpParams()
        .set('email', contactForm.email)
        .set('phone', contactForm.phone)
        .set('company_name', contactForm.company_name)
        .set('company_website', contactForm.company_website)
        .set('subject', contactForm.subject)
        .set('message', contactForm.message);

    const url = baseUrl + 'support/contact';

    return this.http.post(url, params)
        .toPromise();
  }
}
