<p>
  <a
    routerLink="{{rlink}}"
    (click)="linkClicked()"
    [ngClass]="{ 'current-link' : selected, 'no-icon': !icon }"
  >
    <i *ngIf="icon" [class]="icon" aria-hidden="true"></i>
    <img class="svg-icon" *ngIf="svg" [src]="'/assets/icons/' + svg + '.svg'" />
    <span>
      <ng-content></ng-content>
    </span>
  </a>
</p>
