import { Directive, ElementRef, OnInit, Renderer2, Input } from '@angular/core';

@Directive({
    selector: '[appFallbackImage]',
    standalone: false
})
export class FallbackImageDirective implements OnInit {
  @Input() appFallbackImage: string;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {
    const element = this.el.nativeElement as HTMLImageElement;
    const appFallbackImage = this.appFallbackImage;
    const renderer = this.renderer;

    const handler = () => {
      renderer.setAttribute(element, 'src', appFallbackImage || '/assets/images/transparent.gif');
      element.removeEventListener('error', handler);
    };
    element.addEventListener('error', handler);
  }
}
