import {
    Component,
    OnInit,
    Output,
    Input,
    EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DiscountCodesService } from '../../services/discount-codes.service';
import { User } from '../../services/user';
import { DiscountCodeState } from '../types';

@Component({
    selector: 'app-code-activation',
    templateUrl: 'code-activation.component.html',
    styleUrls: ['code-activation.component.scss'],
    standalone: false
})
export class ActivationCodeComponent implements OnInit {
    @Output() verified = new EventEmitter<DiscountCodeState>();
    @Input() accountId: number;
    alert: {type: string; msg: string} = {
        type: '',
        msg: ''
    };
    currentUser: User;
    applyingCode = false;
    codeForm: FormGroup;

    constructor(private discountCodesService: DiscountCodesService,
                private fb: FormBuilder
                ) { }

    ngOnInit() {
        this.setCodeForm();
    }

    setCodeForm() {
        this.codeForm = this.fb.group({
            code: ['', Validators.required]
        });
    }

    setAlertMessage(type: string, message: string) {
        this.alert = {
            type,
            msg: message
        };
    }

    public applyCode(): void {
        if (this.codeForm.valid) {
            this.applyingCode = true;
            this.discountCodesService
                .verifyCode(this.accountId, this.codeForm.get('code').value).then(resp => {
                    this.applyingCode = false;

                    const code = resp.redemption_code.code;
                    const percent = resp.redemption_code.percent;
                    const subId = resp.redemption_code.sub_plan_id ?
                        resp.redemption_code.sub_plan_id : '';

                    this.setAlertMessage(
                        'success',
                        `Your code "${code}" has been applied successfully. You received ${percent}% off discount!`
                    );

                    this.verified.emit({
                        verify: true,
                        percent,
                        code,
                        subId
                    });
                })
                .catch(err => {
                    this.applyingCode = false;
                    let message: string;

                    switch (err.status) {
                        case 400:
                            message = 'Your code is invalid. Try again!';
                            break;
                        case 404:
                            message = 'This code does not exist. Try again!';
                            break;
                        case 429:
                            message = 'This code is not active anymore. Try again!';
                            break;
                        default:
                            message = 'Something went wrong, try again.';
                            break;
                    }
                    this.setAlertMessage(
                        'error',
                        message
                    );
                });
        }
    }

    private resetCodeForm(): void {
        this.codeForm.reset();
    }
}
