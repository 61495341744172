<div class="navbar-toggle" (click)="toggleCollapse()" [ngClass]="{ 'floating': floating }">
  <i class="fa fa-bars"></i>
</div>
<nav class="navbar">
  <ng-content></ng-content>
</nav>
<!--
<div [collapse]="!isCollapsed" class="navbar-expander" (mousedown)="isCollapsed=!isCollapsed">
  <div class="the-arrow"></div>
  <div class="the-bar"></div>
</div>
-->
