import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuditLog } from '../types/audit_log';

const baseUrl = '/api/';

const debugOnly = false;

@Injectable()
export class AuditService {
  constructor(private http: HttpClient) {
  }

  getGroupAuditLog(groupId: number ): Promise<AuditLog[]> {

/*
    if (debugOnly) {
      let sampleLogEntry = [new AuditLog({
        what_changed : 'email address',
        who_changed_email : 'pub@xsact.com',
        who_changed_id : 1000,
        affected_user_publisher : 2000,
        timestamp : new Date,
        ip_address : '192.168.1.1',
        geo_ip_location : {lat:33.6971518, lon: -117.7554595 }
      })];
      let resp = new Promise(function(resolve, reject){
        resolve(sampleLogEntry);
      });
      return resp;
    }
    */
    return this.http.get<AuditLog[]>( baseUrl + 'group/' +
      encodeURIComponent(groupId.toString()) + '/audit/log')
      .toPromise();
  }

  getUserAuditLog() {
    return this.http.get( baseUrl + 'user/audit/log')
      .toPromise();
  }
/*
  private extractData(res: Response) {
    if (res.status !== 200) {
      throw new Error('Bad response status: ' + res.status);
    }
    const body = res;
    const auditLog: Array<AuditLog> = [];
    console.log(body);
    if (body) {
      body.forEach( function(item: any) {
        const auditItem = new AuditLog;
        auditItem.what_changed = item.message;
        auditItem.who_changed_id = item.user_id;
        auditItem.affected_user_publisher = item.account_id;
        auditItem.timestamp = item.timestamp;
        auditItem.ip_address = item.ip_address;
        auditItem.geo_ip_location = item.ip_latlong;

        auditLog.push(auditItem);
      });

      return auditLog;
    }

    return [];
  }
*/
}
