import { Component, Input, OnInit, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'app-subscriptions-pie-chart',
    templateUrl: './subscriptions-pie-chart.component.html',
    styleUrls: ['./subscriptions-pie-chart.component.scss'],
    standalone: false
})
export class SubscriptionsPieChartComponent implements OnInit, OnChanges {
  @Input() pieChartData: number[];
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: 'white',
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        titleColor: 'black',
      },
    },
  };

  public pieChartLabels: string[] = ['Active', 'Expired'];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['#17a5ae', 'orange'],
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pieChartData) {
      this.chart.update();
    }
  }
}
