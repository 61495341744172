import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TokenClass } from '../types/token_class';
import { AccountFunds, Currency } from '../types/account_funds';


/**
 * This class represents the lazy loaded dashboardComponent.
 */
@Component({
    selector: 'app-xsact-currency-selector',
    templateUrl: 'currency-selector.component.html',
    styleUrls: ['currency-selector.component.scss'],
    standalone: false
})
export class CurrencySelectorComponent implements OnInit {
  @Input() set funds(_funds: Partial<AccountFunds>) {
    this.fundsAll = _funds;
    this.fundsExisting = {};
    for(const currency in _funds) {
      if(TokenClass[currency] && _funds[currency] > 0) {
        this.fundsExisting[currency] = _funds[currency];
      }
    }
    if(Object.keys(this.fundsExisting).length === 0) {
      this.fundsExisting[TokenClass.PROD] = 0;
    }
  };
  get funds() {
    if(this.showAllCurrencies) {
      return this.fundsAll;
    } else {
      return this.fundsExisting;
    }
  }
  @Input() selectedCurrency: Currency = Currency.PROD;
  @Input() showAllCurrencies = false;
  @Output() currencyChanged = new EventEmitter<Currency>();
  TokenClass = TokenClass;
  fundsAll: Partial<AccountFunds>;
  fundsExisting: Partial<AccountFunds>;

  selectCurrency(code: Currency) {
    this.selectedCurrency = code;
    this.currencyChanged.emit(code);
  }

  ngOnInit() {
  }
}
