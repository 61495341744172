import { User } from './../services/user';
import { Group } from './../services/group';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  HostListener,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CurUserService } from '../services/cur-user.service';
import { GroupService } from '../services/group.service';
import { forkJoin } from 'rxjs';

import * as _ from 'lodash';

/**
 * This class represents the navigation bar component.
 */
@Component({
    selector: 'app-xsact-header',
    templateUrl: 'xsact-header.component.html',
    styleUrls: ['xsact-header.component.scss'],
    standalone: false
})
export class XsactHeaderComponent implements OnInit, OnDestroy {
  @Input() floating: boolean;
  @ViewChild('userDropdownMenu') userDropdownMenu: ElementRef;
  returnUrl: string;
  headerCollapsedMenuOpen = false;
  currentRoute: string;
  isCollapsed: boolean;
  landingPage: boolean;
  selectedLang: any;

  supportedLangs = [
    {
      key: 'en-US',
      name: 'English',
    },
    {
      key: 'es',
      name: 'Español',
    },
    //  ,{
    //    key: 'jp',
    //    name: '日本語'
    //  }
  ];

  updatedTimestamp: number;

  userItems = [
    { title: 'sign up', link: '/signup' },
    { title: 'login', link: '/login' },
  ];

  isLoggedIn = false;
  user: User;
  groupPublishers: Group & { id: number; own: boolean; selected: boolean }[];

  private redirectCount = 0;
  private userStatusChange;

  constructor(
    private currentUserService: CurUserService,
    private groupService: GroupService,
    private route: ActivatedRoute,
    private router: Router,
    private eRef: ElementRef
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url.replace(/\?.*/, '');
      }
    });

    this.updatedTimestamp = currentUserService.lastUpdatedTimestamp;

    let lang: string;
    try {
      lang = localStorage.getItem('lang') || 'en-US';
    } catch (e) {
      lang = 'en-US';
    }
    this.selectedLang = _.find(this.supportedLangs, { key: lang });
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (
      this.headerCollapsedMenuOpen &&
      this.userDropdownMenu &&
      this.userDropdownMenu.nativeElement &&
      !this.userDropdownMenu.nativeElement.contains(event.target)
    ) {
      if (this.headerCollapsedMenuOpen) {
        this.headerCollapsedMenuOpen = false;
      }
    }
  }

  ngOnInit() {
    this.landingPage = false;
    if (this.router.url === '/' || this.router.url === '') {
      this.landingPage = true;
    }

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '';

    this.userStatusChange = this.currentUserService.curUser$.subscribe((user) => {
      this.isLoggedIn = this.currentUserService.isLoggedIn();
      if (user && this.isLoggedIn) {
        this.user = user;
        this.getGroupPublishers();
      }
    });

    this.windowResize();
  }

  getGroupPublishers() {
    const profileGroups = this.user.groups;

    if (profileGroups && profileGroups.length) {
      forkJoin(
        /* eslint-disable arrow-body-style */
        profileGroups.map((groupId) => {
          return this.groupService.getGroupProfile(groupId);
        })
      ).subscribe((groupPublishers: any) => {
        const publisherIdParam = this.route.firstChild
          ? this.route.firstChild.snapshot.params.publisherId
          : null;
        for (const group of groupPublishers) {
          group.own = group.id === profileGroups[0];
          if (publisherIdParam) {
            group.selected = group.id === parseInt(publisherIdParam, 10);
          } else if (group.own && !publisherIdParam) {
            group.selected = true;
          }
        }
        this.groupPublishers = groupPublishers;
      });
    }
  }

  // switchToPublisherProfile(selectedPublisher: Group & {own: boolean}) {
  //   const currentUrl = this.router.url || null;

  //   for (const group of this.groupPublishers) {
  //     group.selected = group.id === selectedPublisher.id;
  //   }

  //   this.toggleCollapsedNavbar();

  //   if (selectedPublisher.own) {
  //     this.router.navigate(['/publisher', 'summary']);
  //   } else {
  //     this.router.navigate(['/publisher', selectedPublisher.id, 'summary']);
  //   }
  // }

  toggleCollapsedNavbar() {
    this.headerCollapsedMenuOpen = !this.headerCollapsedMenuOpen;
  }

  logout() {
    this.currentUserService.logout(true);
  }

  collapsed(event: any): void {
    // debug('collapsed', event);
  }

  expanded(event: any): void {
    // debug('expanded', event);
  }

  windowResize() {
    if (window.innerWidth < 768) {
      this.isCollapsed = true;
    } else {
      this.isCollapsed = false;
    }
  }

  selectLanguage(lang: string) {
    const selectedLang = _.find(this.supportedLangs, { key: lang });
    if (!selectedLang) {
      // not supported
      return;
    } else {
      this.selectedLang = selectedLang;

      localStorage.setItem('lang', lang);
      window.location.href = '/';
    }
  }

  ngOnDestroy() {
    if (this.userStatusChange) {
      this.userStatusChange.unsubscribe();
    }
  }

  private navigateRoute(path: string) {
    this.redirectCount += 1;

    if (this.redirectCount < 50) {
      this.router.navigate([path]);
    } else {
      console.error('Over Max redirects', path, this.redirectCount);
    }
  }
}
