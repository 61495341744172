// import * as Debug_ from 'debug';
// const Debug = Debug_;
// var debug = Debug('shared:XsactLeftNavComponent');

import { Component, Output, ElementRef, Renderer2, EventEmitter, Input } from '@angular/core';
import { Router, Event, RouterEvent, NavigationStart } from '@angular/router';

import { filter } from 'rxjs/operators';

/**
 * This class represents the navigation bar component.
 */
@Component({
    selector: 'app-xsact-left-nav',
    templateUrl: 'xsact-left-nav.component.html',
    styleUrls: ['xsact-left-nav.component.scss'],
    standalone: false
})
export class XsactLeftNavComponent {
  @Input() floating: boolean;
  @Output() routeChange: EventEmitter<any> = new EventEmitter();

  public currentRoute: string;

  private isCollapsed: boolean;
  private element: any;
  private positioningMode: string;

  constructor(private router: Router, private el: ElementRef, private renderer: Renderer2) {
    this.currentRoute = router.url;
    this.element = el.nativeElement;
    this.positioningMode = 'normal';

    router.events
      .pipe(filter((e: Event | RouterEvent): e is NavigationStart => e instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        window.scrollTo(0, 0);
        this.currentRoute = event.url;
        this.routeChange.emit(this.currentRoute);
      });
    this.collapse();
  }

  collapse() {
    this.renderer.addClass(this.element, 'collapsed');
    this.isCollapsed = true;
  }

  toggleCollapse() {
    if (this.isCollapsed) {
      this.renderer.removeClass(this.element, 'collapsed');
      this.isCollapsed = false;
    } else {
      this.collapse();
    }
  }

  positionNormal() {
    if (this.positioningMode !== 'normal') {
      this.positioningMode = 'normal';
      this.renderer.removeClass(this.element, 'fixed');
      this.renderer.setStyle(this.element, 'bottom', '0');
    }
  }
  positionFixedToTop() {
    if (this.positioningMode !== 'fixed') {
      this.positioningMode = 'fixed';
      this.renderer.addClass(this.element, 'fixed');
    }
    if (this.element.style.bottom !== '0') {
      this.renderer.setStyle(this.element, 'bottom', '0');
    }
  }
  positionFixedToFooter(visibleFooterHeight: number) {
    if (this.positioningMode !== 'fixed') {
      this.positioningMode = 'fixed';
      this.renderer.addClass(this.element, 'fixed');
    }
    this.renderer.setStyle(this.element, 'bottom', visibleFooterHeight + 'px');
  }
}
