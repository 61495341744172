import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'xsactDatePipe',
    standalone: false
})
export class XsactDatePipe implements PipeTransform {
  private datePipe: DatePipe = new DatePipe('en-us');

  transform(value: any, pattern?: string) {
    let datePresentation = '';
    const valueMoment = value;
    switch (pattern) {
      case 'transaction-list':
        if (valueMoment.getDate() === new Date().getDate()) {
            datePresentation = 'Today';
        } else if ((valueMoment.getFullYear() === new Date().getFullYear()) && (valueMoment.getMonth() === new Date().getMonth())) {
            datePresentation = 'This Month';
        } else if ((valueMoment.getFullYear() === new Date().getFullYear()) && (valueMoment.getMonth() === new Date().getMonth() - 1)) {
            datePresentation = 'Last Month';
        } else {
            datePresentation = 'Older';
        }
        break;
      default:
        datePresentation = this.datePipe.transform(value, pattern);
    }
    return datePresentation;
  }
}
