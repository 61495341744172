import { Component, Input } from '@angular/core';

import { AuditLog } from '../../types/audit_log';

import * as _ from 'lodash';

const defaultOptions = {
  displayFormat: '',
  maxTransactions: 50,
  limitToRecentDays: 9999,
  excludeRecentDays: 0,
  noItemsMessage: 'No matches.',
};

@Component({
    selector: 'app-elements-audit-log',
    templateUrl: 'elements-audit-log.component.html',
    styleUrls: ['../elements.scss', 'elements-audit-log.component.scss'],
    standalone: false
})
export class ElementsAuditLogComponent {
  @Input() auditLogList: Array<AuditLog> = new Array();

  // Settings governing how list container is rendered.
  @Input() optionsForList: any = {};

  // Passthrough settings that end up in 'ElementsTransactionSingleComponent'.
  @Input() optionsForSingle: any = {};

  preparedList: AuditLog[];

  getOption(optionToGet: string): string {
    const localDefault = (defaultOptions as any)[optionToGet];
    const optionsHashToUse = this.optionsForList;
    const passedOption = optionsHashToUse[optionToGet];
    let returnValue = '';

    if (typeof localDefault === 'undefined') {
      console.error(
        `Requested option ${optionToGet} for 'elements-transaction-list' is INVALID.`,
        defaultOptions
      );
    } else if (passedOption) {
      returnValue = passedOption;
    } else {
      returnValue = localDefault;
    }

    return returnValue;
  }

  getLogs(): AuditLog[] {
    const preparedList: any = this.auditLogList;

    if (this.preparedList) {
      return this.preparedList;
    }

    this.preparedList = this.auditLogList;

    if (this.preparedList.length) {
      this.preparedList = preparedList;
    }

    return preparedList;
  }

  hasLogs(): boolean {
    return this.auditLogList.length > 0;
  }
}
