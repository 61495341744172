import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Account } from './account';
import { Group } from './group';
import { User } from './user';
import { Transaction } from './transaction';
import { Subscription } from './../types/subscription';
import { GiftSubscription } from './../types/gift_subscription';
import { Bank } from '../types/bank';
import { map } from 'rxjs/operators';

const baseUrl = '/api';
const baseAdminUrl = baseUrl + '/admin';
const accountLoadCache = new Map<number, Promise<Group | User>>();

export enum ERefundReason {
  DUPLICATE = 'duplicate',
  FRAUD = 'fraudulent',
  REQUEST = 'requested_by_customer',
}

export enum ESearchFields {
  Email = 'email1',
  Domain = 'email1_domain',
  Created = 'created'
}

@Injectable()
export class AdminService {

  constructor(private http: HttpClient) {

  }

  authError(error: Response) {
    console.log('UserServie authentication ERROR');
    console.log(error);

  }

  respOK()  {
    console.log('respOK');
  }

  creditUser(userId: number, amount: number, comment: string) {
    return this.http.post<Transaction>(`/api/admin/account/${userId}/free-service-credit`, { amount, comment })
      .toPromise();
  }

  refundTransaction(transactionId: number, reason?: ERefundReason | string) {
    return this.http.put<Transaction>(`/api/transact/refund/by-id/${transactionId}`, { reason })
      .toPromise();
  }

  searchAccounts(field: string, operation: string, value: string, limit = 200): Observable<Account[]> {
    // NOTE operation doesn't get encoded correctly
    //    var params = new URLSearchParams();
    //    params.set('field', field);
    //    params.set('operation', operation);
    //    params.set('value', value);

    const params = 'field=' + encodeURIComponent(field)
      + '&operation=' + encodeURIComponent(operation)
      + '&value=' + encodeURIComponent(value)
      + '&limit=' + (limit);


    return this.http.get<Account[]>( baseAdminUrl + '/account/search?' + params);
  }

  public getUserLogins(accountId: string | number): Promise<any> {
    return this.http.get(`/api/account/${accountId}/logins`).toPromise();
  }

  listPublishers() {
    return this.http.get<{ groups: Array<Group> }>('/api/admin/groups/list').pipe(map(r => r.groups));
  }

  getPublisherProfile(publisherId: number): Promise<Group> {
    if (accountLoadCache.has(publisherId)) {
      return accountLoadCache.get(publisherId) as Promise<Group>;
    }
    const promise = this.http.get<Group>( baseAdminUrl + '/account/' + publisherId.toString() + '/profile')
      .toPromise();
    accountLoadCache.set(publisherId, promise);
    return promise;
  }

  getPublisherBank(publisherId: number): Promise<Bank> {
    const promise = this.http.get<Bank>( baseUrl + '/group/' + publisherId.toString() + '/bank')
      .toPromise();
    return promise;
  }

  getUserProfile(userId: number): Promise<User> {
    if (accountLoadCache.has(userId)) {
      return accountLoadCache.get(userId) as Promise<User>;
    }
    const promise = this.http.get<User>( baseAdminUrl + '/account/' + userId.toString() + '/profile')
      .toPromise();
    accountLoadCache.set(userId, promise);
    return promise;
  }

  editUserProfile(userId: number, body: Partial<User>): Promise<User> {
    const promise = this.http.post<User>(baseAdminUrl + '/account/' + userId.toString() + '/profile', body)
      .toPromise();
    
    return promise;
  }

  getUserSubscriptions(userId: number) {
    return this.http.get<{
      subscriptions: Subscription[];
      gifts: GiftSubscription[];
    }>(`/api/account/${userId}/subscriptions`);
  }

  getUserExpiredSubscriptions(userId: number) {
    return this.http.get<{
      subscriptions: Subscription[];
      gifts: GiftSubscription[];
    }>(`/api/account/${userId}/subscriptions?remove_expired=false`);
  }

  closeUserAccount(userId: number) {
    return this.http.delete<User>( baseAdminUrl + '/account/' + userId.toString() + '/profile')
      .toPromise();
  }

  refundUserSubscription(accountId: number, subscription: Subscription): Promise<Subscription> {
    return this.http
      .put<Subscription>(baseUrl + '/account/' + accountId + '/subscription/refund/' + subscription.id, {})
      .toPromise();
  }

  closeUserSubscription(accountId: number, subscription: Subscription): Promise<Subscription> {
    return this.http
      .put<Subscription>(baseUrl + '/account/' + accountId + '/subscription/CLOSE/' + subscription.id, {})
      .toPromise();
  }

  /*
  private extractData(res: Response) {
    console.log('map data');
    console.log(res);
    if (res.status !== 200) {
      throw new Error('Bad response status: ' + res.status);
    }
    const body = res;
    console.log(body);
    if (body) {
      // TODO see about caching user data

      return body;
    }

    return { };
  }
  */
}


