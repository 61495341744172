import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

/**
 * This class represents the navigation bar component.
 */
@Component({
    selector: 'app-xsact-footer',
    templateUrl: 'xsact-footer.component.html',
    styleUrls: ['xsact-footer.component.scss'],
    standalone: false
})
export class XsactFooterComponent implements OnInit {
  buildDate = '';
  gitHash = '';

  ngOnInit() {
    this.buildDate = new Date(environment.buildDate).toUTCString();
    this.gitHash = environment.gitHash;
  }
}
