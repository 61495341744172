import { Component, OnInit, Input, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { CurUserService } from '../../services/cur-user.service';
import { XctFormValidationService } from '../../services/form_validation.service';
import { User } from '@shared/services/user';
import { ToastService } from '@shared/services/toast.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-form-elements-change-password',
    templateUrl: 'form-elements-change_password.component.html',
    styleUrls: ['../form-elements.scss', 'form-elements-change_password.component.scss'],
    providers: [XctFormValidationService],
    standalone: false
})
export class FormElementsChangePasswordComponent implements OnInit, OnDestroy {
  @ViewChild('confirmPasswordChangeModal', { read: TemplateRef })
  confirmPasswordChangeModal: TemplateRef<void>;
  passwordModalRef: BsModalRef;

  @Input() user: User;
  editPasswordForm: FormGroup;
  error: Record<string, boolean> = {};
  formSuccessText = '';
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private curUserService: CurUserService,
    private toastService: ToastService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.editPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      newPasswordConfirm: ['', [Validators.required, Validators.minLength(6)]],
      currentPassword: ['', [Validators.required]],
    });
  }

  ngOnDestroy() {
    if (this.passwordModalRef) {
      this.passwordModalRef.hide();
    }
  }

  savePassword() {
    if (this.editPasswordForm.invalid) {
      return;
    }
    this.passwordModalRef = this.modalService.show(this.confirmPasswordChangeModal, {
      class: 'modal-sm',
    });
  }

  confirmChangePassword() {
    if (this.editPasswordForm.invalid) {
      return;
    }

    // Clear any extant messages.
    this.clearFormMessages();
    this.loading = true;

    const hashForAPI = {
      new_password: this.editPasswordForm.get('newPassword').value,
      old_password: this.editPasswordForm.get('currentPassword').value,
    };

    // console.warn(`form-elements-change_password: hashForAPI`, hashForAPI);

    // This gets back a Promise.
    const responsePromise = this.curUserService.updateUserPassword(hashForAPI);

    // API returns error messages that need to show up on form,
    // e.g., "Current password as entered does not match what is on record."
    responsePromise
      .then((response: any) => {
        this.formSuccessText = response.message;
        this.editPasswordForm.reset();
        this.loading = false;

        // Changing password invalidates the token, so we need to have the user log back in.
        this.toastService.showToast('Password updated. Please log in again.');
        this.curUserService.clearSession(true);
      })
      .catch((resp: any) => {
        this.error = {};
        this.error[resp.error.code] = true;
        this.loading = false;
      });
  }

  clearFormMessages(): void {
    this.error = {};
    this.formSuccessText = '';
  }
}
