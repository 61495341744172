import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, PLATFORM_ID, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { EMPTY, Subject, of } from 'rxjs';

@Component({
    selector: 'app-recaptcha',
    templateUrl: 'recaptcha.component.html',
    styleUrls: ['recaptcha.component.scss'],
    standalone: false
})
export class RecaptchaComponent implements OnDestroy {
  @ViewChild('greInvisibleElement') greInvisibleElement: ElementRef;
  @ViewChild('greElement') greElement: ElementRef;

  currentInvisibleWidget?: number;
  currentWidget?: number;
  currentCaptchaObs?: Subject<string>;
  lastCaptchaSolve?: string;
  captchaActive = false;

  private options = {
    'sitekey': environment.recaptchaKey,
    'callback': (response) => {
      this.lastCaptchaSolve = response;
      this.captchaActive = false;
      this.currentCaptchaObs.next(response);
    },
    'expired-callback': () => {
      this.captchaActive = true;
      this.lastCaptchaSolve = undefined;
    },
    'error-callback': () => {
      this.lastCaptchaSolve = undefined;
      this.captchaActive = true;
      this.currentCaptchaObs.error('captcha error');
    },
  };

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngOnDestroy() {
    this.reset();
  }

  challenge() {
    if (isPlatformBrowser(this.platformId) && window.grecaptcha?.enterprise?.render) {
      if (this.lastCaptchaSolve) {
        return of(this.lastCaptchaSolve);
      } else if (!this.currentCaptchaObs) {
        this.captchaActive = true;
        this.currentCaptchaObs = new Subject<string>();
        if (this.currentWidget) {
          window.grecaptcha.enterprise.reset(this.currentWidget);
        }

        this.currentWidget = window.grecaptcha.enterprise.render(
          this.greElement.nativeElement,
          this.options
        );
      }
      return this.currentCaptchaObs.asObservable();
    } else {
      return EMPTY;
    }
  }

  reset() {
    if (isPlatformBrowser(this.platformId) && window.grecaptcha?.enterprise?.render) {
      if (this.currentWidget !== undefined) {
        grecaptcha.enterprise.reset(this.currentWidget);
      }
      delete this.lastCaptchaSolve;
    }
  }
}
