import { Component, OnInit, ViewChild, ElementRef, Input, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { CurUserService } from '../services/cur-user.service';
import { DiscountCodesService, Code } from '../services/discount-codes.service';
import { UserService } from './../services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { User } from '../services/user';
import { GroupService, UtilityService } from '../index';

@Component({
    selector: 'app-discount-codes',
    templateUrl: 'discount-codes.component.html',
    styleUrls: ['discount-codes.component.scss'],
    standalone: false
})
export class DiscountCodesComponent implements OnInit {
  @ViewChild('questionModal', { read: TemplateRef }) questionModal: TemplateRef<void>;
  @Input() accountType: string | any;

  alerts: { type: string; msg: string; timeout: number }[] = [];
  currentUser: User;
  loadingCodes = true;
  loadingPublishers = true;
  addingCode = false;
  codeType: string;
  codesForm: FormGroup;
  accountId: number;
  publisherId: number;
  redemptionPublishers = [];
  codesList = [];
  selectedSubscription: any;
  selectedStep = 0;

  questionModalRef: BsModalRef;
  modalData: any;
  noSubscriptionSetup = false;

  constructor(
    private currentUserService: CurUserService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private discountCodesService: DiscountCodesService,
    private userService: UserService,
    private fb: FormBuilder,
    private groupService: GroupService,
    private utilitySvc: UtilityService
  ) {}

  ngOnInit() {
    this.setCodesForm();
    this.selectCodeType('single', true);
    this.currentUserService.getUser().then((usr) => {
      this.currentUser = usr;
      console.log('account view type', this.accountType);
      if (this.accountType === 'user') {
        this.accountId = this.currentUser.id;
        this.getCurrentCodes();
        this.getPublishers();
      } else if (this.accountType === 'publisher') {
        this.groupService.getCurrentPublisherId(this.route).then((publisherId: number) => {
          if (publisherId) {
            this.accountId = publisherId;
            this.getCurrentCodes();
            this.getPublishers();
          }
        });
      } else {
        console.error('Invalid account type', this.accountType);
        this.accountId = this.currentUser.id; // default
        this.getCurrentCodes();
        this.getPublishers();
      }
    });
  }

  setCodesForm() {
    this.codesForm = this.fb.group({
      code: ['', Validators.required],
      percent: ['', Validators.required],
      limit: ['', Validators.required],
      count: ['', Validators.required],
    });
  }

  selectCodeType(type: string, noStep?: boolean) {
    this.codeType = type;

    if (type === 'single') {
      this.codesForm.get('code').enable();
      this.codesForm.get('limit').enable();
      this.codesForm.get('count').disable();
    } else {
      this.codesForm.get('code').disable();
      this.codesForm.get('limit').disable();
      this.codesForm.get('count').enable();
    }

    if (!noStep) {
      this.setStep(3);
    }
  }

  selectPublisher(publisher?: any) {
    if (!publisher) {
      this.selectSubscription();
    } else {
      this.setStep(1);
    }

    for (const p of this.redemptionPublishers) {
      if (publisher) {
        p.selected = p.id === publisher.id;
        this.publisherId = publisher.id;
      } else {
        p.selected = false;
        p.checked = false;
      }
    }
  }

  selectSubscription(subscription?: any): void {
    this.selectedSubscription = subscription ? subscription : null;

    for (const p of this.redemptionPublishers) {
      for (const s of p.subscriptions) {
        if (subscription) {
          s.selected = s.id === subscription.id;
        } else {
          s.selected = false;
        }
      }
    }

    if (subscription) {
      this.setStep(2);
    }
  }

  selectAllSubsPublisher(publisher?: any): void {
    this.selectSubscription();

    for (const p of this.redemptionPublishers) {
      if (publisher) {
        if (p.id === publisher.id) {
          p.checked = true;
          this.selectedSubscription = publisher;
        } else {
          p.checked = false;
        }
      } else {
        p.checked = false;
      }
    }

    if (publisher) {
      this.setStep(2);
    }
  }

  setStep(step: number) {
    this.selectedStep = step;
  }

  isPublisherSelected(): boolean {
    return this.redemptionPublishers.filter((p) => p.selected).length > 0;
  }

  isSubscriptionSelected(): boolean {
    return this.selectedSubscription;
  }

  displayPlan(plan: string): string {
    let period = '';
    switch (plan) {
      case 'DAILY':
        period = 'Day';
        break;
      case 'MONTHLY':
        period = 'Month';
        break;
      case 'YEARLY':
        period = 'Year';
        break;
      default:
    }
    return period;
  }

  displayPlanLabel(plan: string): string {
    if (!plan) {
      return 'All Subscriptions';
    } else {
      if (plan.split('_').length) {
        const planType = plan.split('_')[1];

        switch (planType) {
          case 'DAILY':
            return 'Daily Subscription';
            break;
          case 'MONTHLY':
            return 'Monthly Subscription';
            break;
          case 'YEARLY':
            return 'Yearly Subscription';
            break;
          default:
        }
      } else {
        return 'All Subscriptions';
      }
    }
  }

  addDiscountCode() {
    this.addingCode = true;

    const singleCode: Code = {
      percent: parseInt(this.codesForm.get('percent').value, 10),
      publisher_id: 0,
      active: true,
      limit: parseInt(this.codesForm.get('limit').value, 10),
      sub_plan_id: '',
    };

    const batchCodes: Code = {
      percent: parseInt(this.codesForm.get('percent').value, 10),
      publisher_id: 0,
      limit: 1,
      count: parseInt(this.codesForm.get('count').value, 10),
      sub_plan_id: '',
    };

    if (this.selectedSubscription && this.selectedSubscription.subscriptions) {
      console.log('any subscription from selected publisher');
      Object.assign(this.codeType === 'single' ? singleCode : batchCodes, {
        publisher_id: this.publisherId,
        sub_plan_id: 'ANY',
      });
    } else if (this.selectedSubscription.plan_type) {
      console.log('selected subscription');
      Object.assign(this.codeType === 'single' ? singleCode : batchCodes, {
        publisher_id: this.publisherId,
        sub_plan_id: this.selectedSubscription.id,
      });
    }

    if (this.codeType === 'single') {
      this.discountCodesService
        .createSingleCode(this.accountId, this.codesForm.get('code').value, singleCode)
        .then((res) => {
          this.setAlertMessage('success', 'Your discount code has been added successfully.', 10000);
          this.getCurrentCodes();
          this.resetCodesForm();
          this.addingCode = false;
        })
        .catch((err) => {
          this.addingCode = false;
          let message: string;

          switch (err.status) {
            case 400:
              message = 'Your code is invalid. Try again!';
              break;
            case 409:
              message = 'This code is already used by another user. Try again!';
              break;
            default:
              message = 'Something went wrong, try again.';
              break;
          }
          this.setAlertMessage('danger', message, 10000);
        });
    } else {
      this.discountCodesService.createBatchOfCodes(this.accountId, batchCodes).then((res) => {
        this.setAlertMessage('success', 'Your batch of codes has been added successfully.', 10000);
        this.getCurrentCodes();
        this.resetCodesForm();
        this.addingCode = false;
      });
    }
  }

  setAlertMessage(type: string, message: string, timeout?: number) {
    this.alerts = [];
    this.alerts.push({
      type,
      msg: message,
      timeout: timeout ? timeout : 5000,
    });
  }

  deleteCode(code: Code) {
    this.modalData = code;
    this.openQuestionModal();
  }

  removeCode() {
    this.discountCodesService
      .deleteDiscountCode(this.modalData.publisher_id, this.modalData.code)
      .then((resp) => {
        this.modalData = null;
        this.getCurrentCodes();
        this.setAlertMessage('success', 'Your code has been removed successfully.', 10000);
      })
      .catch((err) => {
        this.setAlertMessage('danger', 'Something went wrong. Please try again!', 10000);
      });
  }

  openQuestionModal() {
    this.questionModalRef = this.modalService.show(this.questionModal, { class: 'modal-lg' });
  }

  private resetCodesForm(): void {
    this.selectAllSubsPublisher();
    this.selectPublisher();
    this.selectSubscription();
    setTimeout(() => {
      this.codesForm.reset();
    }, 0);
    this.setStep(0);
  }

  private getPublishers(): void {
    this.loadingPublishers = true;
    if (this.accountType === 'publisher') {
      const publisherGroupId = this.accountId ? this.accountId : null;

      if (publisherGroupId) {
        this.userService
          .getGroupSubscriptions(publisherGroupId)
          .then((subs) => {
            this.loadingPublishers = false;
            if (subs.length) {
              this.userService.getPublisherInfo(publisherGroupId).then((publisher: any) => {
                publisher.id = publisherGroupId;
                this.redemptionPublishers.push(publisher);

                for (const p of this.redemptionPublishers) {
                  p.selected = false;
                  p.subscriptions = subs;
                }
              });

              this.noSubscriptionSetup = false;
            } else {
              this.noSubscriptionSetup = true;
            }
          })
          .catch((err) => {
            this.loadingPublishers = false;
            this.setAlertMessage(
              'danger',
              'Publisher subscriptions unavailable at this time, try again later.'
            );
            console.log('Error retrieving publisher information', err);
          });
      } else {
        this.loadingPublishers = false;
        this.setAlertMessage('danger', 'Your publisher group is not correct. Try again later.');
      }
    } else {
      this.discountCodesService.getRedemptionPublishers(this.accountId).then((publishers) => {
        this.loadingPublishers = false;
        for (const p of publishers) {
          p.selected = false;
          this.userService.getGroupSubscriptions(p.id).then((subs) => {
            p.subscriptions = subs;
          });
        }
        this.redemptionPublishers = publishers;
      });
    }
  }

  private getCurrentCodes(): void {
    this.loadingCodes = true;
    this.discountCodesService.getRedemptionCodeList(this.accountId).then((res) => {
      this.loadingCodes = false;
      this.codesList = res.codes ? res.codes : [];

      for (const c of this.codesList) {
        this.userService.getPublisherInfo(c.publisher_id).then((publisherInfo) => {
          c.publisher = publisherInfo;
        });
      }
    });
  }
}
