<div class="body-container">
  <ng-content></ng-content>
</div>

<app-toast></app-toast>

<ng-template #appNotificationModalWrapper>
  <section class="tt__modal app-notification-modal">
    <div class="modal-header">
      <h3>{{ appModalData.title }}</h3>
    </div>
    <div class="modal-body question-mark-bg">
      <i class="fa fa-question-circle"></i>
      <p class="question">
        {{ appModalData.message }}
      </p>
    </div>
    <div class="modal-footer text-right">
      <button (click)="appNotificationModal.hide()" class="tt--button button--blue large-2x">
        Close
      </button>
    </div>
  </section>
</ng-template>
